export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45')
];

export const server_loads = [];

export const dictionary = {
		"/": [5],
		"/about-us": [24],
		"/airdrop-guide": [26],
		"/airdrop": [25],
		"/dev/feed/logic": [27],
		"/dev/feed/watchhistory": [28],
		"/dev/sentry/errors": [29],
		"/faq": [30],
		"/faq/install-app": [31],
		"/(feed)/(splash)/feed": [6,[2,3]],
		"/(feed)/(splash)/feed/no-videos": [8,[2,3]],
		"/(feed)/(splash)/feed/[id=videoId]": [7,[2,3]],
		"/(feed)/(splash)/hotornot": [9,[2,3,4]],
		"/(feed)/(splash)/hotornot/no-videos": [11,[2,3,4]],
		"/(feed)/(splash)/hotornot/[id=videoId]": [10,[2,3,4]],
		"/(feed)/leaderboard": [12,[2]],
		"/(feed)/menu": [13,[2]],
		"/(feed)/menu/auth": [14,[2]],
		"/(feed)/menu/reauth": [15,[2]],
		"/(feed)/notifications": [16,[2]],
		"/privacy-policy": [32],
		"/profile": [33],
		"/profile/[id]": [34],
		"/profile/[id]/edit": [35],
		"/profile/[id]/lovers": [36],
		"/profile/[id]/loving": [37],
		"/profile/[id]/post": [38],
		"/profile/[id]/post/[postId]": [39],
		"/profile/[id]/speculations": [40],
		"/profile/[id]/speculations/[postId=videoId]": [41],
		"/refer-earn": [42],
		"/terms-of-service": [43],
		"/upload": [44],
		"/upload/new": [45],
		"/(feed)/users/[id]": [17,[2]],
		"/(feed)/waitlist": [18,[2]],
		"/(feed)/wallet-staging": [21,[2]],
		"/(feed)/wallet-staging/[id]": [22,[2]],
		"/(feed)/wallet-staging/[id]/send": [23,[2]],
		"/(feed)/wallet": [19,[2]],
		"/(feed)/wallet/transactions": [20,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';